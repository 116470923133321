$text-color: #555;
$body-bg: #fff;
$navbar-light-color: $text-color;
$navbar-light-active-color: $text-color;
$navbar-light-brand-color: $text-color;
$body-color: $text-color;
$theme-colors: (
  "primary": #ffed69
);

@import "~bootstrap/scss/bootstrap.scss";

body {
  font-family: "M PLUS Rounded 1c", sans-serif;
}

.navbar-dark .navbar-toggler {
  border-width: 0;
}

.cb-markdown {
  line-height: 1.9rem;

  blockquote {
    color: #666;
    margin: 0;
    padding-left: 3em;
    border-left: 0.5em #eee solid;
  }

  code {
    background-color: rgba(27, 31, 35, 0.05);
    padding: 0.2em 0.4em;
    color: #24292e;
  }

  pre code {
    border-left: 5px solid #ffed69;
  }

  tr {
    border-top: 1px solid #c6cbd1;
    background: #fff;
  }

  th,
  td {
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }

  table tr:nth-child(2n) {
    background: #f6f8fa;
  }

  h3 {
    margin-top: 3rem;
    margin-bottom: 2rem;
  }

  h2 {
    margin-bottom: 2rem;
  }
}
